<template>
  <div class="navbarmobileFix">
    <div class="AboutUsfirstBanner">
      <div class="container pl-0 pr-0">
        <!-- FOR DESKTOP  -->
        <div class="firstBannertext text-align text-center d-none d-lg-block">
          <h1>Building a social impact initiative</h1>
          <h1>in the healthcare segment</h1>
          <div class="firstBannerHrLine"></div>
        </div>
        <!-- FOR MOBILE  -->
        <div class="firstBannertext text-align text-center d-block d-lg-none">
          <h1>Building a social</h1>
          <h1>impact initiative in the</h1>
          <h1>healthcare segment</h1>
          <div class="firstBannerHrLine"></div>
        </div>
      </div>
    </div>

    <div class="secondCircleSection container text-align text-center">
      <div class="current-scope-heading">
        <h1>Our Current Scope</h1>
      </div>
      <div class="circleForScope">
        <div class="current-scope-subHeading">
          <h2>Primary care</h2>
        </div>
        <!-- FOR DESKTOP  -->
        <div class="current-scope-subText d-none d-lg-block">
          <h3>We believe that good primary care</h3>
          <h3>facilities are the foundation for a</h3>
          <h3>good healthcare delivery system.</h3>

          <h3 class="current-scope-breakTop">
            We aim to facilitate a good care 
          </h3>
          <h3> experience for the public from</h3>
          <h3>neighbourhood healthcare</h3>
          <h3>professionals.</h3>
        </div>
        <!-- FOR MOBILE  -->
        <div class="current-scope-subText d-block d-lg-none">
          <h3>We believe that good</h3>
          <h3>primary care facilities are</h3>
          <h3>the foundation for a good</h3>
          <h3>healthcare delivery</h3>
          <h3>system.</h3>

          <h3 class="current-scope-breakTop">We aim to facilitate a good</h3>
          <h3>care experience for</h3>
          <h3>the public from</h3>
          <h3>neighbourhood</h3>
          <h3>healthcare</h3>
          <h3>professionals.</h3>
        </div>
      </div>
    </div>

    <div class="thirdBoxSection container text-align text-center">
      <div class="focus-heading">
        <h1>Our Focus</h1>
      </div>
      <!-- FOR DESKTOP  -->
      <div class="d-none d-lg-block">
        <fieldset class="strenthenCapacity-sub-heading">
          <legend>
            <span class="sub-titileMobile">Support</span>
            <span class="sub-titileMobile">neighbourhood clinics</span>
          </legend>
          <div class="strenthenCapacity-sub-text">
            <h3>RxIx is a digital platform designed to</h3>
            <h3>aid the public in opting</h3>
            <h3>for neighbourhood clinics</h3>
            <h3>for their everyday healthcare needs.</h3>

            <h3 class="strenthenCapacity-breakTop">
              It is a societal platform that aims to
            </h3>
            <h3>enhance the trust and respect</h3>
            <h3>between the</h3>
            <h3>neighbourhood healthcare</h3>
            <h3>professional and the patient.</h3>
          </div>
        </fieldset>
      </div>
      <!-- FOR MOBILE  -->
      <div class="d-block d-lg-none">
        <fieldset class="strenthenCapacity-sub-heading">
          <legend>
            <span class="sub-titileMobile">Support</span>
            <span class="sub-titileMobile">nearby clinics</span>
          </legend>
          <div class="strenthenCapacity-sub-text">
            <h3>RxIx is a digital platform</h3>
            <h3>designed to aid the public</h3>
            <h3>in opting for neighbourhood clinics</h3>
            <h3>for their everyday healthcare needs.</h3>

            <h3 class="strenthenCapacity-breakTop">
              It is a societal platform that
            </h3>
            <h3>aims to enhance the trust and</h3>
            <h3>respect between the</h3>
            <h3>neighbourhood healthcare</h3>
            <h3>professional and the patient.</h3>
          </div>
        </fieldset>
      </div>

      <!-- FOR DESKTOP  -->
      <div class="d-none d-lg-block">
        <fieldset class="enhance-sub-heading">
          <legend class="sub-title">Enhance health literacy</legend>
          <div class="enhance-sub-text">
            <h3>Knowledgeable patients are likely to</h3>
            <h3>be more engaged in shared</h3>
            <h3>decision-making and self-</h3>
            <h3>management.</h3>

            <h3 class="enhance-breakTop">
              We strive to enhance health literacy
            </h3>
            <h3>by integrating patient education as</h3>
            <h3>part of the healthcare delivery</h3>
            <h3>process.</h3>

            <h3 class="enhance-breakTop">We also work to help patients find</h3>
            <h3>strength from the community,</h3>
            <h3>harnessing the power of shared</h3>
            <h3>experience and individual insights.</h3>
          </div>
        </fieldset>
      </div>
      <!-- FOR MOBILE  -->
      <div class="d-block d-lg-none">
        <fieldset class="enhance-sub-heading">
          <legend>
            <span class="sub-titileMobile">Enhance</span>
            <span class="sub-titileMobile">health literacy</span>
          </legend>

          <div class="enhance-sub-text">
            <h3>Knowledgeable patients are</h3>
            <h3>likely to be more engaged in</h3>
            <h3>shared decision-making and</h3>
            <h3>self-management.</h3>

            <h3 class="enhance-breakTop">We strive to enhance health</h3>
            <h3>literacy by integrating patient</h3>
            <h3>education as part of the</h3>
            <h3>healthcare delivery process.</h3>

            <h3 class="enhance-breakTop">We also work to help patients</h3>
            <h3>find strength from the</h3>
            <h3>community, harnessing the</h3>
            <h3>power of shared experience</h3>
            <h3>and individual insights.</h3>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="fourcircleTeamImage container text-align text-center">
      <div class="team-heading">
        <h1>The Team</h1>
      </div>
      <div class="teamImages container d-block d-md-none d-lg-block">
        <div class="threeimage">
          <div>
            <img
              class="mb-3 team"
              src="@/assets/rxix-team-img/aniruddhaDoctor.png"
            />
            <h5 class="teamNames">Dr. Aniruddha Malpani</h5>
            <a
              href="https://www.drmalpani.com/"
              class="icoLinkedin"
              title="Globe"
              target="_blank"
            >
              <img
                class="linkedIn-logo"
                src="@/assets/rxix-team-img/browser.png"
              />
            </a>
          </div>
          <div>
            <img class="mb-3 team" src="@/assets/rxix-team-img/rameshwar.jpg" />
            <h5 class="teamNames">V Rameshwar</h5>
            <div>
              <a
                href="https://in.linkedin.com/in/vrameshwar"
                class="icoLinkedin"
                title="Globe"
                target="_blank"
              >
                <img
                  class="linkedIn-logo"
                  src="@/assets/rxix-team-img/linkedin.png"
                />
              </a>
            </div>
          </div>
          <div>
            <img
              class="mb-3 team"
              src="@/assets/rxix-team-img/vijaykumar.jpg"
            />
            <h5 class="teamNames">Vijayakumar Kaliyaperumal</h5>
            <div>
              <a
                href="https://www.linkedin.com/in/vijayakumark/"
                class="icoLinkedin"
                title="Globe"
                target="_blank"
              >
                <img
                  class="linkedIn-logo"
                  src="@/assets/rxix-team-img/linkedin.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="threeimage">
          <div>
            <img class="mb-3 team" src="@/assets/rxix-team-img/Rajkumar.png" />
            <h5 class="teamNames">Dr. S Rajkumar</h5>
            <div>
              <!-- <a href="/presign" class="icoLinkedin" title="Globe" target="_blank"> -->
              <!-- <img
                class="linkedIn-logo"
                src="@/assets/rxix-team-img/browser.png"
              /> -->
              <!-- </a> -->
            </div>
          </div>
          <div>
            <img class="mb-3 team" src="@/assets/rxix-team-img/venkatT.jpg" />
            <h5 class="teamNames">Venkatesan Thangaraj</h5>
            <div>
              <a
                href="https://www.linkedin.com/in/venkatesant/"
                class="icoLinkedin"
                title="Globe"
                target="_blank"
              >
                <img
                  class="linkedIn-logo"
                  src="@/assets/rxix-team-img/linkedin.png"
                />
              </a>
            </div>
          </div>
          <div>
            <img
              class="mb-3 team"
              src="@/assets/rxix-team-img/SridharChakravarthi.jpg"
            />
            <h5 class="teamNames">Flt. Lt. M Sridhar Chakravarthi</h5>
            <div>
              <a
                href="https://www.linkedin.com/in/fltltmsridharchakravarthi/"
                class="icoLinkedin"
                title="Globe"
                target="_blank"
              >
                <img
                  class="linkedIn-logo"
                  src="@/assets/rxix-team-img/linkedin.png"
                />
              </a>
            </div>
          </div>
          <div>
            <img
              class="mb-3 team"
              src="@/assets/rxix-team-img/venkatkumarS.jpeg"
            />
            <h5 class="teamNames">Venkatkumar Sethupathy</h5>
            <div>
              <a
                href="https://www.linkedin.com/in/venkatkumar-sethupathy-82284113/"
                class="icoLinkedin"
                title="Globe"
                target="_blank"
              >
                <img
                  class="linkedIn-logo"
                  src="@/assets/rxix-team-img/linkedin.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="teamImages container d-none d-md-block d-block d-lg-none d-sm-none"
      >
        <div class="threeimage">
          <div>
            <img
              class="mb-3 team"
              src="@/assets/rxix-team-img/aniruddhaDoctor.png"
            />
            <h5 class="teamNames">Dr. Aniruddha Malpani</h5>
            <a
              href="https://www.drmalpani.com/"
              class="icoLinkedin"
              title="Globe"
              target="_blank"
            >
              <img
                class="linkedIn-logo"
                src="@/assets/rxix-team-img/browser.png"
              />
            </a>
          </div>
          <div>
            <img class="mb-3 team" src="@/assets/rxix-team-img/rameshwar.jpg" />
            <h5 class="teamNames">V Rameshwar</h5>
            <div>
              <a
                href="https://in.linkedin.com/in/vrameshwar"
                class="icoLinkedin"
                title="Globe"
                target="_blank"
              >
                <img
                  class="linkedIn-logo"
                  src="@/assets/rxix-team-img/linkedin.png"
                />
              </a>
            </div>
          </div>
          <div>
            <img
              class="mb-3 team"
              src="@/assets/rxix-team-img/vijaykumar.jpg"
            />
            <h5 class="teamNames">Vijayakumar Kaliyaperumal</h5>
            <div>
              <a
                href="https://www.linkedin.com/in/vijayakumark/"
                class="icoLinkedin"
                title="Globe"
                target="_blank"
              >
                <img
                  class="linkedIn-logo"
                  src="@/assets/rxix-team-img/linkedin.png"
                />
              </a>
            </div>
          </div>
          <div>
            <img class="mb-3 team" src="@/assets/rxix-team-img/Rajkumar.png" />
            <h5 class="teamNames">Dr. S Rajkumar</h5>
            <div>
              <!-- <a href="/presign" class="icoLinkedin" title="Globe" target="_blank"> -->
              <!-- <img
                class="linkedIn-logo"
                src="@/assets/rxix-team-img/browser.png"
              /> -->
              <!-- </a> -->
            </div>
          </div>
        </div>
        <div class="threeimage">
          <div>
            <img class="mb-3 team" src="@/assets/rxix-team-img/venkatT.jpg" />
            <h5 class="teamNames">Venkatesan Thangaraj</h5>
            <div>
              <a
                href="https://www.linkedin.com/in/venkatesant/"
                class="icoLinkedin"
                title="Globe"
                target="_blank"
              >
                <img
                  class="linkedIn-logo"
                  src="@/assets/rxix-team-img/linkedin.png"
                />
              </a>
            </div>
          </div>
          <div>
            <img
              class="mb-3 team"
              src="@/assets/rxix-team-img/SridharChakravarthi.jpg"
            />
            <h5 class="teamNames">Flt. Lt. M Sridhar Chakravarthi</h5>
            <div>
              <a
                href="https://www.linkedin.com/in/fltltmsridharchakravarthi/"
                class="icoLinkedin"
                title="Globe"
                target="_blank"
              >
                <img
                  class="linkedIn-logo"
                  src="@/assets/rxix-team-img/linkedin.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div>
          <img
            class="mb-3"
            style="
              padding: 10px;
              width: 200px;
              height: 200px;
              border: 2px solid #34989f;
              border-radius: 50%;
            "
            src="@/assets/rxix-team-img/venkatkumarS.jpeg"
          />
          <h5 class="teamNames">Venkatkumar Sethupathy</h5>
          <div>
            <a
              href="https://www.linkedin.com/in/venkatkumar-sethupathy-82284113/"
              class="icoLinkedin"
              title="Globe"
              target="_blank"
            >
              <img
                class="linkedIn-logo"
                src="@/assets/rxix-team-img/linkedin.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="final-section container text-align text-center">
      <div class="join-movement-Heading">
        <h1>Join the Movement</h1>
      </div>
      <div class="explore-option-sub-text">
        <h2>Explore options to partner</h2>
        <h2>with us to make an impact.</h2>
      </div>
    </div>

    <section class="banner-two mb-5" id="contact" style="margin-top: 5rem">
      <div class="container mt-5">
        <div class="row">
          <div class="col-lg-6">
            <div class="shadow-sm p-3 mb-5 bg-white rounded">
              <div class="cards mb-5">
                <div class="card-body">
                  <div
                    class="form-header blue accent-1 text-center list-group shadow-box"
                  >
                    <div class="row">
                      <div class="col-12" style="display: flex">
                        <img
                          src="~@/assets/img/message-icon.png"
                          style="width: 10%; height: 35px"
                        />
                        <h3><i class="fas fa-envelope"></i> Write to us:</h3>
                      </div>
                    </div>
                  </div>
                  <form role="form" class="contact-form" style="margin-top: 6%">
                    <div class="form-group">
                      <label>Name*</label>
                      <input type="text" class="form-control" v-model="name" />
                    </div>
                    <div class="form-group">
                      <label>Mobile</label>
                      <input
                        type="text"
                        maxlength="10"
                        class="form-control"
                        v-model="mobile"
                        @input="mobilenumbervalidate"
                      />
                    </div>
                    <div class="form-group">
                      <label>Email*</label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="email"
                        @input="handleEmailInput"
                      />
                    </div>
                    <div class="form-group">
                      <label>Message*</label>
                      <textarea
                      maxlength="50"
                        class="form-control"
                        rows="5"
                        v-model="message"
                      ></textarea>
                    </div>
                    <div class="form-group">Note: Message size should be a minimum of 50 characters.</div>
                  </form>
                  <button
                    style="float: right;"
                    type="submit"
                    class="btn btn-blue-color text-white"
                    @click="addQuery()"
                    :disabled="!isVerifyData" 
                    v-bind:class="[!isVerifyData ? 'disableBtn' : '']"
                  >
                    SUBMIT
                  </button>
                </div>
                <!-- card body div ends -->
              </div>
              <!-- card div ends-->
            </div>
          </div>
          <!-- col div ends-->

          <div class="col-lg-6">
            <div class="shadow-sm p-3 mb-5 bg-white rounded">
              <div class="cards">
                <div class="card-body">
                  <div
                    class="form-header blue accent-1 text-center list-group shadow-box"
                  >
                    <div class="row">
                      <div class="col-12" style="display: flex">
                        <img
                          src="~@/assets/img/location.png"
                          style="width: 10%; height: 35px"
                        />
                        <h3><i class="fas fa-map-marker-alt"></i> Find us:</h3>
                      </div>
                    </div>
                  </div>
                  <!-- <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.838007411241!2d80.21649731416905!3d13.045981216773134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266c1a3776ff3%3A0x7dc8413377bf3d33!2sBambeeQ+Solutions+Pvt+Ltd!5e0!3m2!1sen!2sin!4v1563183903720!5m2!1sen!2sin"
                    height="231"
                    frameborder="0"
                    style="width: 100%; border: 0; margin-top: 6%"
                    allowfullscreen=""
                  ></iframe> -->
                  <p class="find-us mt-3">
                    Bamboo Wellness Solutions LLP<br />
                    NSIC STP Complex,<br />
                    No. 403 & 404, 3rd Floor Business Park,<br />
                    B-24, Guindy Industrial Estate, Ekkatuthangal,<br />
                    Chennai, Tamil Nadu 600032
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- row div ends -->
      </div>
      <!-- container div ends -->
    </section>
  </div>
  <MobileNavbar></MobileNavbar>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defineComponent } from "vue";
import MobileNavbar from "../views/common-mobile-footer.vue";

export default defineComponent({
  components: {
    MobileNavbar,
  },
  name: "AboutUs",
  props: {},
  data() {
    return {
      name: "",
      mobile: "",
      email: "",
      message: "",
    };
  },
  computed: {
    isVerifyData() {
  const nameRegex = /^[A-Za-z\s]+$/; // Only alphabets and spaces allowed for name
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex pattern
  const mobileRegex = /^[6-9]\d{9}$/; // Mobile number regex pattern

  const isNameValid = this.name && nameRegex.test(this.name); // Name is required and valid
  const isEmailValid = this.email && emailRegex.test(this.email); // Email is required and valid
  const isMobileValid = !this.mobile || mobileRegex.test(this.mobile); // Mobile is optional, if provided it should be valid
  const isMessageValid = this.message && this.message.length <= 50; // Message is required and less than or equal to 50 characters

  // Return true only if all conditions are met
  return isNameValid && isEmailValid && isMobileValid && isMessageValid;
}
  },
  methods: {
    mobilenumbervalidate(){
      this.mobile = this.mobile.replace(/\D/g, '');
    },
    handleEmailInput() {
      this.email = this.email.toLowerCase();
    },
    async addQuery() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      const payload = {
        name: this.name,
        mobile: this.mobile,
        email: this.email,
        description: this.message,
      };
      await axios
        .post(`${BASE_API_URL}/userquery/post`, payload)
        .then((response) => {
          if (response.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Email send successfully",
              showConfirmButton: false,
              timer: 2000,
            });
            this.name='',
            this.mobile = '',
            this.email = '',
            this.message = ''
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        });
    },
  },
});
</script>

<style>
  .bottom-link-navbar{
    display: block !important;
  }
  li.nav-item.nav{
    display: block !important; 
  }

h5.teamNames {
  font-size: 20px;
}

img.linkedIn-logo {
  width: 40px;
  height: 40px;
}

div.AboutUsfirstBanner {
  margin-top: 4rem;
  background-color: #34989f;
  padding: 13rem 0rem 13rem 0rem;
}

div.firstBannertext h1 {
  color: #fff;
  font-size: 50px;
}

div.firstBannerHrLine {
  border-bottom: 5px solid #fff;
  margin-left: auto;
  margin-right: auto;
  width: 650px;
}

div.secondCircleSection,
div.thirdBoxSection,
div.fourcircleTeamImage,
div.final-section {
  margin-top: 5rem;
}

div.circleForScope {
  margin-top: 3rem;
    border-radius: 50%;
    border: 2px solid #00979e;
    width: 668px;
    padding-top: 85px;
    padding-bottom: 85px;
    margin-left: auto;
    margin-right: auto;
}

div.current-scope-heading h1,
div.thirdBoxSection h1,
div.fourcircleTeamImage h1,
div.join-movement-Heading h1 {
  font-size: 50px;
  color: #000;
}

div.current-scope-subHeading h2 {
  font-size: 45px;
}

div.current-scope-subText {
  margin-top: 3rem;
}

div.current-scope-subText h3,
div.strenthenCapacity-sub-text h3,
div.enhance-sub-text h3,
div.explore-option-sub-text h2 {
  font-size: 35px;
}

h3.current-scope-breakTop,
h3.strenthenCapacity-breakTop,
h3.enhance-breakTop {
  margin-top: 2rem;
}

.strenthenCapacity-sub-heading,
.enhance-sub-heading {
  display: inline-block;
  border: 2px solid #00979e;
  border-radius: 50px;
  padding: 50px;
  margin-top: 1rem;
}

fieldset.strenthenCapacity-sub-heading legend,
fieldset.enhance-sub-heading legend {
  background: #fff;
  padding: 4px 10px;
  margin: 0 auto;
  display: block;
  float: none;
  width: auto;
  font-size: 45px;
}

div.threeimage {
  margin-top: 2rem;
  grid-gap: 2rem;
  display: flex;
  justify-content: space-evenly;
}

.teamImages div.threeimage div img.team {
  padding: 10px;
  width: 200px;
  height: 200px;
  border: 2px solid #34989f;
  border-radius: 50%;
}

div.explore-option-sub-text {
  margin-top: 2rem;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.list-group {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.shadow-box {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%), 0 2px 4px 0 rgb(0 0 0 / 12%) !important;
}

.form-header.blue.accent-1.list-group.shadow-box {
  margin-top: -58px;
  background: #e4f1f2;
  /* color: #00979e; */
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.shadow-sm.p-3.mb-5.bg-white.rounded {
  height: 90%;
}

p.find-us {
  /* color: #00979e; */
  font-size: 17px;
}

legend span.sub-titileMobile {
  display: block;
}

@media screen and (max-width: 991px) {
  .navbarmobileFix {
    margin-bottom: 7rem;
  }

  div.AboutUsfirstBanner {
    margin-top: 0rem;
    padding: 8rem 0rem 8rem 0rem;
  }

  div.firstBannertext h1 {
    font-size: 35px;
  }

  div.current-scope-heading h1,
  div.thirdBoxSection h1,
  div.fourcircleTeamImage h1,
  div.join-movement-Heading h1 {
    font-size: 35px;
  }

  div.firstBannerHrLine {
    width: 350px;
    border-bottom: 4px solid #fff;
  }

  div.secondCircleSection,
  div.thirdBoxSection,
  div.fourcircleTeamImage,
  div.final-section {
    margin-top: 3rem;
  }

  div.circleForScope {
    margin-top: 2rem;
    width: 335px;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  div.current-scope-subHeading h2 {
    font-size: 30px;
  }

  div.current-scope-subText {
    margin-top: 1.5rem;
  }

  h3.current-scope-breakTop,
  h3.strenthenCapacity-breakTop,
  h3.enhance-breakTop {
    margin-top: 1.5rem;
  }

  div.current-scope-subText h3,
  div.strenthenCapacity-sub-text h3,
  div.enhance-sub-text h3,
  div.explore-option-sub-text h2 {
    font-size: 23px;
  }

  fieldset.strenthenCapacity-sub-heading legend,
  fieldset.enhance-sub-heading legend {
    font-size: 30px;
  }

  .strenthenCapacity-sub-heading,
  .enhance-sub-heading {
    padding: 40px;
  }

  div.threeimage {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .teamImages div.threeimage div img.team {
    margin-top: 20px;
  }

  div.explore-option-sub-text {
    margin-top: 1rem;
  }

  legend span.sub-titileMobile {
    display: block;
  }
}

@media screen and (max-width: 769px) {
  div.threeimage {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 576px) {
  h5.teamNames {
    font-size: 18px;
  }

  div.firstBannertext h1 {
    font-size: 25px;
  }

  div.current-scope-heading h1,
  div.thirdBoxSection h1,
  div.fourcircleTeamImage h1,
  div.join-movement-Heading h1 {
    font-size: 25px;
  }

  div.current-scope-subHeading h2 {
    font-size: 23px;
  }

  div.current-scope-subText h3,
  div.strenthenCapacity-sub-text h3,
  div.enhance-sub-text h3,
  div.explore-option-sub-text h2 {
    font-size: 18px;
  }

  div.AboutUsfirstBanner {
    padding: 6rem 0rem 6rem 0rem;
  }

  div.firstBannerHrLine {
    width: 235px;
    border-bottom: 3px solid #fff;
  }

  div.circleForScope {
    width: 263px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .strenthenCapacity-sub-heading,
  .enhance-sub-heading {
    padding: 15px;
  }

  fieldset.strenthenCapacity-sub-heading legend,
  fieldset.enhance-sub-heading legend {
    font-size: 23px;
  }
}
</style>
